@tailwind base;
@tailwind components;
@tailwind utilities;


.wrap-text {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.wrap-text > div {
    position: relative;
    top: -5px
}

.wrap-text > span {
    max-width: 86%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.answred {
    color: #189B3D !important;
}

@font-face {
    font-family: rubikFont;
    src: url(../src/Rubik/static/Rubik-Regular.ttf);
}

@font-face {
    font-family: Medium-rubikFont;
    src: url(../src/Rubik/static/Rubik-Medium.ttf);
}

@font-face {
    font-family: Bold-rubikFont;
    src: url(../src/Rubik/static/Rubik-SemiBold.ttf);
}

body {
    font-family: rubikFont;
}

.loader {
    background: url(../src/assets/loader.gif);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position : fixed;
    z-index: 9999;
    top : 0;
    left : 0;
    height : 100%;
    width : 100%;
    cursor : wait;
}

.logoImage {
    padding: 21px 19px;
}

.acdemicReport {
    padding: 16px 32px;
}

.acdemicReport .percentageRatio {
    background: white;
    border-radius: 8px;
    padding: 0px 12px;
}

.acdemicReport .percentageRatio p {
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #47529B;
}

.acdemicReport h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #1C3E57;
    font-family: rubikFont;
}

.studentTableWrapper {
    padding: 24px 32px;
    text-align: left;
    border-bottom: 1px solid #E9E9E9;
    z-index: 1;
    position: sticky;
    top: 0;
    overflow: auto;
}

.studentTableWrapper table.studentTable thead tr th {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    font-family: Medium-rubikFont;
    color: #607889;
    padding-right: 40px;
}

.studentTableWrapper table.studentTable tbody tr td {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1C3E57;
    padding-right: 40px;
}

.studentContent {
    padding: 32px;
    width: 100%;
    margin-bottom: 144px;
}

.studentContent .studentContent-Left {
    border: 1px solid;
    border-color: #DBE0E3;
    border-radius: 16px;
    align-self: flex-start;
    width: 100%;
    max-width: 440px;
    transition: .3s;
    margin-right: 32px;
}

.studentContent .studentContent-Left.openMenu {
    max-width: 82px;
}

.CloseIcon-Wrap a.CloseIcon {
    position: absolute;
    right: 17px;
    top: 32px;
    width: 32px;
    height: 32px;
}

.CloseIcon-Wrap a.CloseIcon:before,
.CloseIcon-Wrap a.CloseIcon:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 3px;
    background-color: #47529b;
}

.CloseIcon-Wrap a.CloseIcon:before {
    transform: rotate(45deg);
}

.CloseIcon-Wrap a.CloseIcon:after {
    transform: rotate(-45deg);
}

.studentContent .studentContent-Left>ul.navTop {
    padding: 24px;
}

.studentContent .studentContent-Left>ul.navTop>li {
    background: #F7F8F9;
    padding: 12px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-left: 0;
    border-radius: 12px;
    font-family: Bold-rubikFont;
    color: #212123;
    margin-bottom: 10px;
}

.studentContent .studentContent-Left>ul.navTop>li .nav-Wrapper {
    display: flex;
    align-items: center;
}

.studentContent .studentContent-Left>ul.navTop>li .nav-Wrapper span:first-child {
    flex: 1 1 auto;
}

.studentContent .studentContent-Left>ul.navTop>li .nav-Wrapper span:nth-child(2) {
    color: rgba(33, 33, 35, 0.5);
    font-weight: 400;
    font-family: 'Medium-rubikFont';
    font-size: 17px;
    line-height: 24px;
    padding-right: 10px;
}

.studentContent .studentContent-Left>ul.navTop>li ul li {
    font-family: rubikFont;
    font-weight: 400;
    font-size: 16px;
}

.studentContent .studentContent-Left ul.navTop li .rightIcon {
    padding-top: 4px;
    color: #E5E5E5;
}

.studentContent .studentContent-Left>ul.navTop>li .nav-Wrapper svg {
    fill: rgba(33, 33, 35, 0.5);
    width: 30px;
    height: 25px;
}

.studentContent .studentContent-Left ul.navTop li ul li svg {
    width: 25px;
    height: 20px;
}

.studentContent .studentContent-Left .menuTop {
    padding: 24px;
    border-bottom: 1px solid;
    border-color: #DBE0E3;
}

.studentContent .studentContent-Left.openMenu .menuTop {
    border-bottom: none;
}

.studentContent .studentContent-Left .menuTop .menuIcon .menuInner {
    display: flex;
    align-items: center;
}

.studentContent .studentContent-Left .menuTop .menuIcon .menuInner p {
    padding-left: 10px;
    font-family: Bold-rubikFont;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: #1C3E57;
}

.studentContent .studentContent-Left.openMenu .menuTop .menuIcon .menuInner p {
    display: none;
}

.studentContent .studentContent-right {
    border: 1px solid;
    border-color: #DBE0E3;
    border-radius: 16px;
    width: 100%;
}

.studentContent .studentContent-right .studentContentTop {
    padding: 22px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid;
    border-color: #DBE0E3;
}

.studentContent .studentContent-right .studentContentTop p {
    display: inline-block;
}

.studentContent .studentContent-right .studentContentTop .menuIcon {
    display: none;
}

.studentContent .studentContent-right .studentContentTop .menuIcon+p {
    font-weight: 500;
    font-size: 24px;
    color: #002B48;
    line-height: 36px;
    font-family: Bold-rubikFont;
    flex: 1 1 auto;
}

.studentContent .studentContent-right .studentContentTop p:last-child {
    float: right;
    font-size: 17px;
    line-height: 24px
}

.studentContent .studentContent-right .studentContentBottom {
    padding: 24px;
}

.studentContent .studentContent-right .studentContentBottom p:first-child {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
}

.studentContent .studentContent-right .studentContentBottom p:nth-child(2) {
    padding-top: 36px;
    color: #607889;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    width: 100%;
    padding: 0;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .innerBoxes p {
    padding: 0;
    width: 100%;
}

/* .studentContent .studentContent-right .studentContentBottom li {
        padding-top: 20px;
    } */

.studentContent .studentContent-right .studentContentBottom li img.checkImg {
    width: 32px;
    height: 32px;
    margin-right: 20px;
}

.studentContent .studentContent-right .studentContentBottom li img.unCheckImg {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    position: relative;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper {
    padding-top: 24px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper nav {
    border: 2px solid;
    border-color: #1D4D70;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    opacity: 0;
    visibility: hidden;
}
.studentContent .studentContent-right .studentContentBottom .selectWrapper nav.openDropdown {
    opacity: 1;
    visibility: visible;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .UncheckBox {
    width: 32px;
    height: 32px;
    border: 2px solid;
    border-color: #1D4D70;
    border-radius: 6px;
    margin-right: 20px;
    flex: 1 0 auto;
    position: relative;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .checkBox {
    width: 32px;
    height: 32px;
    border: 2px solid;
    border-color: #1D4D70;
    border-radius: 6px;
    margin-right: 20px;
    background: #1D4D70;
    flex: 1 0 auto;
    position: relative;
}
.studentContent .studentContent-right .studentContentBottom .selectWrapper .checkBox::before {
    position: absolute;
    left: 0;
    top: 51%;
    height: 21%;
    width: 2px;
    background-color: #ffffff;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
    border-radius: 10px;
 }

.studentContent .studentContent-right .studentContentBottom .selectWrapper .checkBox::after {
    position: absolute;
    left: 0;
    top: 19px;
    height: 2px;
    width: 60%;
    background-color: #ffffff;
    content: "";
    transform: translateX(11px) rotate(-48deg);
    transform-origin: left bottom;
    border-radius: 10px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .Disable {
    width: 32px;
    height: 32px;
    border: 2px solid;
    border-color: #adadad;
    border-radius: 6px;
    margin-right: 20px;
    background: #d5d6d7;
    position: relative;
    flex: 1 0 auto;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper li {
    color: #002B48;
    font-size: 16px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper button {
    border-color: #1D4D70;
    padding-top: 13px;
    border-width: 2px;
    padding-bottom: 13px;
    border-radius: 8px;
    align-items: center;
    color: #BDBDBD;
}
.studentContent .studentContent-right .studentContentBottom .selectWrapper button.borderNone {
    border-radius: 8px 8px 0 0;
}
.studentContent .studentContent-right .studentContentBottom .selectWrapper button svg {
    fill: #002B48;
    width: 30px;
    height: 30px;
}
.studentContent .studentContent-right .studentContentBottom .selectWrapper button .rotateIcon {
    transform: rotate(180deg);
}
.studentContent .studentContent-right .studentContentBottom .selectWrapper .group:focus button {
    border-radius: 8px 8px 0 0;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder {
    border: 1px solid;
    border-color: #DBE0E3;
    border-radius: 16px;
    padding: 24px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper.selectWrapper-lowercase {
    text-transform: lowercase;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .innerBoxes img {
    width: 32px;
    height: 32px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .outboxes img {
    width: 32px;
    height: 32px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .innerBoxes li {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #002B48;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .innerBoxes {
    padding: 15px 64px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .outboxes+.innerBoxes {
    background-image: url(./assets/arrow_top_right.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px 13px;
}
/*
.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .outboxes+.innerBoxes .checkBox {
    width: 34.74px;
}

.studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .outboxes+.innerBoxes .UncheckBox {
    width: 34.74px;
} */

footer {
    padding: 10px;
    box-shadow: 0px -10px 10px rgb(38 50 56 / 8%);
    position: fixed;
    bottom: 0;
}

footer .percentageRatio {
    width: 100%;
    max-width: 400px;
    border-radius: 12px;
    padding: 12px 16px;
    margin-left: 58px;

}

footer .percentageRatio p {
    padding-bottom: 12px;
}

footer .backBtnWrapper {
    flex: 1 1 auto;
    margin-left: 80px;
}

footer .backBtnWrapper button {
    border: 1px solid;
    border-color: #002B48;
    padding: 8px 20px;
    font-family: 'Bold-rubikFont';
    transition: .2s;
}

footer .backBtnWrapper button:hover {
    background: #002B48;
}


footer .backBtnWrapper button p {
    color: #002B48;
}

footer .backBtnWrapper button:hover p {
    color: #fff;
}

footer .backBtnWrapper button svg {
    fill: #002B48;
}

footer .backBtnWrapper button:hover svg {
    fill: #fff;
}

footer .nextBtnWrapper button {
    padding: 8px 20px;
    font-family: 'Bold-rubikFont';
    transition: .2s;
    border: 1px solid #DE706C;
}

footer .nextBtnWrapper button:hover {
    background: transparent;
    border: 1px solid #DE706C;
    color: #DE706C;
}

@media screen and (max-width:767px) {
    .studentContent .studentContent-right .studentContentTop {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;
    }
    .studentContent .studentContent-right .studentContentBottom .selectWrapper .selectBorder .innerBoxes {
        padding: 15px 0px 15px 64px;
    }
    footer {
        padding: 16px 24px;
        position: inherit;
    }
    .logoImage {
        padding: 16px 6px;
    }

    .logoImage img {
        width: 175px;
    }

    .acdemicReport {
        padding: 8px 16px;
    }

    .acdemicReport h3 {
        font-size: 18px;
    }

    .acdemicReport .percentageRatio p {
        font-size: 16px;
    }

    .studentContent .studentContent-Left .menuTop .menuIcon {
        opacity: 0;
    }

    .studentTableWrapper {
        padding: 16px;
        position: inherit;
    }

    .studentTableWrapper table.studentTable thead tr th {
        padding-right: 24px;
        min-width: 115px;
    }

    .studentContent {
        display: block;
        padding: 0;
        margin-bottom: 0;
    }

    .studentContent .studentContent-right .studentContentTop .menuIcon {
        display: block;
    }

    .studentContent .studentContent-right .studentContentTop .menuIcon+p {
        padding-left: 20px;
        font-size: 18px;
        line-height: 30px;
    }

    .studentContent .studentContent-Left {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        border: none;
        background: #fff;
        border-radius: 0;
        width: 0;
        overflow: hidden;
        margin: 0;
    }

    .studentContent .studentContent-Left.openMenu {
        max-width: inherit;
        width: 90%;
        overflow: auto;
        height: 100%;
        border-right: 1px solid #DBE0E3;
        transition: .3s;
    }

    .studentContent .studentContent-right {
        border: none;
    }

    .studentContent .studentContent-right .studentContentBottom p:first-child {
        font-size: 24px;
        line-height: 28px;
    }

    .studentContent .studentContent-Left.openMenu ul.navTop {
        display: block !important;
    }

    footer .percentageRatio {
        display: none;
    }

    footer .backBtnWrapper {
        margin-left: 0;
    }
}

.singlecheckradio label {
    position: relative;
    padding-left: 50px;
}

.singlecheckradio label::before {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url("./assets/uncheck.png");
    background-repeat: no-repeat;
    background-size: 34px;
    background-position: center center;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 5px;
}

.singlecheckradio input {
    display: none;
}

.singlecheckradio input:checked+label::before {
    /* background-color: red; */
    background-image: url("./assets/check.png");
    background-repeat: no-repeat;
    background-size: 34px;
    background-position: center center;
}

@media screen and (max-width:380px) {
    .acdemicReport h3 {
        font-size: 15px;
    }
    .acdemicReport .percentageRatio p {
        font-size: 14px;
    }
}